import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const RoggendorfThenhoven: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.RoggendorfThenhoven,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Roggendorf/Thenhoven"
          d="M163.1,153v-0.3l-0.4-0.2V152c-0.1-0.1-0.3-0.3-0.5-0.3v-0.4l0.2-0.2c15-2.9,31.3-14.3,43.5-23.2 c0.2,0,12,34,12.1,34.1l16-12l18-8l22,52l8,8l-10-2l36,68c-16.6,1.3-62.3,5-78.3,4c-6.4-4.2-19.5-12.8-25.8-17 c0.1-1.6,0.7-10,0.7-11.4c-3.3-3-7.5-7.7-12.4-7.1c-1.4-2.1-8-12.9-9.6-15.2l-4.6,4.1c-2.1-2.6-4-6.6-6.5-8.9 c-4.1,5.2-10.7,10.2-16.8,12.8l-2.5-4.9c-3.5,1.6-8.7,4.7-12.1,6.6c0.2-2.5-5.2-14.3-6-16.7c0.3-0.2,0.8-0.5,1.1-0.7 c-0.1-0.1-1.8-2.7-1.9-2.7c-6.2,3.7-13.3,8.3-19.5,12.3c-1.3-3-4.1-5.5-5.4-8.5c-0.6-3.5-3.2-6.7-3.3-10.3c-4.1-5.1-8-10.9-10.8-17 c-2.2-7-2.7-15.1-7.9-20.8c-2.4-1.8-6.1-7.9-8.5-9.7c-3.7-3.2-8.5-6.1-11.2-10.2l-2.5,0.4l-0.9-4c-0.6-0.4-2.5-1.8-3-2.2l-6.4,2.1 c-0.7-1.9-3.3-4-4-5.8c-0.4-3.4-2.3-7.7-2.5-11.1l-0.7-0.9c-1-8-0.4-9.7,2.7-17.3c11-6.8,23.1-13,34.3-19.5 c3.2,2.2,8.4,3.5,11.7,5.5c-0.4,2.8,1.7,5.8,2.1,8.4c-0.2,0.9-0.4,2.9,0,3.8c1.3,2,3.8,3.4,5.7,4.7c2.5-0.8,3.5,2.8,4.8,4.2 c2.7,3,4.9,7.1,7.8,10l-1.7,0.6l0.7,1.5c1.9-1.1,4.1-0.9,5.9,0.2c0.3,4.4,0.7,9.6,0,14c1.7,7.7,6.3,15,7,23.1 c1.5,4,6.1,11.7,7.4,15.5c0.6,0.4,1.9,1.4,2.5,1.8c0.8,0,1.7,0.6,2.3,1.1c2.5-0.1,6.3,0.3,8.8,0.1c1.1,0.5,2.3,1.2,3.6,0.9l1.1,0.7 l1.5-0.1c0,0-0.5-7.5-0.5-7.8c-0.6-1.1-1.7-2.9-1.5-4.2c0.9-4.1,2.4-9.3,4.7-12.9c0.8-0.5,2,0.3,2.8,0l0.8-0.9 c0.8,0.3,1.5-2.2,1.8-2.6C163,156,163.1,153,163.1,153z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 178.5393 186.7637)"
        >
          <tspan x="0" y="0">
            Roggendorf/
          </tspan>
          <tspan x="0" y="14.4">
            Thenhoven
          </tspan>
        </text>
      </g>
    </>
  );
});
